"use client";

import { useMemo } from "react";
import Link from "next/link";
import { type DeepReadonly } from "@/utils/tsmagic";
import WatermarkLogo from "public/images/logoEndcapTransparent.png";
import GiftWrapsLogo from "public/svgs/wrapgiftslogo.svg";
import { AbsoluteFill, Img, Sequence } from "remotion";
import { type z } from "zod";

import { type CustomCardObjectSchema } from "../core/derivedSchema";
import { useCurrentFrameStyle } from "../core/useCurrentFrameStyle";
import { Bubbles } from "./customElements/Bubbles";
import { ConfettiPop } from "./customElements/ConfettiPop";
import { FlowerExplosion } from "./customElements/FlowerExplosion";
import { Scatter_OLD } from "./customElements/Scatter";
import { Snow } from "./customElements/Snow";
import { Trail } from "./customElements/Trail";

// If you add a value to the metaschema, update the customCardObjectEditor and add a editor function for the value

export type CustomCardObject = z.infer<typeof CustomCardObjectSchema>;
export type ReadonlyCustomCardObject = DeepReadonly<CustomCardObject>;

export const CustomCardObjectComponent: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }) => {
  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <CustomCardObjectComponentInner cardObject={cardObject} />
    </Sequence>
  );
};

const CustomCardObjectComponentInner: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }) => {
  const { outerStyle, innerStyle } = useCurrentFrameStyle(cardObject);

  return useMemo(() => {
    switch (cardObject.metadata.customObjectName) {
      case "FlowerExplosion":
        return (
          <div style={{ zIndex: cardObject.transform.zIndex }}>
            <FlowerExplosion />
          </div>
        );
      case "WaterMarkOverlay": {
        return (
          <div style={outerStyle}>
            <div style={innerStyle}>
              <GiftWrapsLogo />
            </div>
          </div>
        );
      }
      case "Scatter": {
        return <Scatter_OLD cardObject={cardObject} />;
      }
      case "Bubbles": {
        return <Bubbles cardObject={cardObject} />;
      }
      case "ConfettiPop": {
        return <ConfettiPop cardObject={cardObject} />;
      }
      case "Trail": {
        return <Trail cardObject={cardObject} />;
      }
      case "Snow": {
        return <Snow cardObject={cardObject} />;
      }
      case "Watermark": {
        return (
          <Link href="/?category=free" target={"_blank"} style={outerStyle}>
            <Img
              src={WatermarkLogo.src}
              style={innerStyle}
              key={cardObject.id}
            />
          </Link>
        );
      }
      default:
        return (
          <div className="h-full w-full bg-purple-400">
            <div style={outerStyle}>
              <div style={innerStyle}>
                <GiftWrapsLogo />
              </div>
            </div>
          </div>
        );
    }
  }, [cardObject, innerStyle, outerStyle]);
};
