import { v4 } from "uuid";

import { CardObjectSchema, type CardObjectType } from "./derivedSchema";

const parseCardObjectData = (data: unknown[]) => {
  const out = new Array<CardObjectType>();
  data.forEach((item, index) => {
    if (typeof item !== "object") throw new Error("Data type is not an object");
    const res = CardObjectSchema.safeParse({ ...item, id: v4() });
    if (res.success) out.push(res.data);
    else
      console.error(`Failed to parse card object at index ${index}`, res.error);
  });
  return out;
};

export const loadWrapVideoData = (data: unknown[]) => {
  const out = parseCardObjectData(data);
  return out;
};
