"use client";

import { type DeepReadonly } from "@/utils/tsmagic";
import DefaultImage from "public/images/defaultcard.png";
import { Img, Sequence } from "remotion";
import { type z } from "zod";

import { useRemotionAsset } from "../core/AssetManager";
import { type ImageCardObjectSchema } from "../core/derivedSchema";
import { useCurrentFrameStyle } from "../core/useCurrentFrameStyle";

export type ImageCardObject = z.infer<typeof ImageCardObjectSchema>;
export type ReadonlyImageCardObjectType = DeepReadonly<ImageCardObject>;

export const ImageCardObjectComponent: React.FC<{
  cardObject: ReadonlyImageCardObjectType;
}> = ({ cardObject }) => {
  const { outerStyle, innerStyle } = useCurrentFrameStyle(cardObject);
  const src = useRemotionAsset(cardObject.metadata.assetURL);

  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <div style={outerStyle}>
        <Img
          src={src ?? DefaultImage.src}
          style={innerStyle}
          key={cardObject.metadata.assetURL}
        />
      </div>
    </Sequence>
  );
};
