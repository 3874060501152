import { v4 } from "uuid";

import { type BaseCardObjectType } from "../core/baseSchema";
import { type CardObjectType } from "../core/derivedSchema";

type baseCardObjectDataParams = {
  startFrame: number;
  durationInFrames: number;
  x: number;
  y: number;
};

const baseCardObjectData = (
  params: baseCardObjectDataParams,
): Omit<BaseCardObjectType, "label" | "type" | "metadata" | "id"> => {
  return {
    sequence: {
      from: params.startFrame,
      durationInFrames: params.durationInFrames,
    },
    transform: {
      x: params.x, //wrapVideo.resolutionX / 2,
      y: params.y, //wrapVideo.resolutionY / 2,
      scale: 500,
      rotate: 0,
      zIndex: 1,
    },
    animations: [],
    isEditable: true,
  };
};

type CreateCardObjectReturnType<T extends CardObjectType["type"]> = Extract<
  CardObjectType,
  { type: T }
>;

const RawTextOverrides = {
  type: "RAWTEXT",
  metadata: {
    value: "RawText",
    style: {
      color: "black",
      bgColor: "",
      bold: false,
      italic: false,
      font: "Arial",
      underline: false,
      overline: false,
    },
  },
  label: "New Raw Text Object",
} as const;

const RichTextOverrides = {
  type: "RICHTEXT",
  metadata: {
    lexicalJSON: {
      root: {
        children: [
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
        ],
        direction: null,
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    },
    rawHTML: "",
  },
  label: "New Rich Text Object",
} as const;

const ImageOverrides = {
  metadata: { assetURL: "" },
  label: "New Image Object",
  type: "IMAGE",
} as const;

const VideoOverrides = {
  metadata: { assetURL: "", preload: false, volume: 100 },
  label: "New Video Object",
  type: "VIDEO",
  version: 1,
} as const;

const RoseExplosionOverrides = {
  metadata: { customObjectName: "FlowerExplosion" },
  label: "New Flower Explosion Object",
  type: "CUSTOM",
} as const;

const AudioOverrides = {
  label: "New Audio Object",
  metadata: {
    volume: 100,
    startFrom: 0,
    playbackRate: 1,
    audioSrcUrl: undefined,
  },
  type: "AUDIO",
} as const;

const ScatterOverrides = {
  metadata: { customObjectName: "Scatter" },
  label: "New Scatter Animation",
  type: "CUSTOM",
} as const;

const ConfettiPopOverrides = {
  metadata: { customObjectName: "ConfettiPop" },
  label: "New ConfettiPop Animation",
  type: "CUSTOM",
} as const;

const BubblesOverrides = {
  metadata: { customObjectName: "Bubbles" },
  label: "New Bubbles Animation",
  type: "CUSTOM",
} as const;

const SnowOverrides = {
  metadata: { customObjectName: "Snow" },
  label: "New Snow Animation",
  type: "CUSTOM",
} as const;

const TrailOverrides = {
  metadata: { customObjectName: "Trail" },
  label: "New Trail Animation",
  type: "CUSTOM",
} as const;

const GiftWrapLogoOverrides = {
  metadata: { customObjectName: "Watermark" },
  label: "GiftWrapLogo",
  type: "CUSTOM",
} as const;

function createCardObjectData<T extends CardObjectType["type"]>(
  params: baseCardObjectDataParams,
  overrides?: {
    type: T;
    label: string;
    metadata: CreateCardObjectReturnType<T>["metadata"];
  },
): CreateCardObjectReturnType<T> {
  const data = {
    ...baseCardObjectData(structuredClone(params)),
    ...structuredClone(overrides),
    id: v4(),
  };
  return data as unknown as CreateCardObjectReturnType<T>;
}

export class CardObjectFactory {
  createCardObjectDataParams;
  createCardObjectAnimationDataParams;
  constructor(currentFrame: number, resolutionX: number, resolutionY: number) {
    this.createCardObjectDataParams = {
      startFrame: currentFrame,
      durationInFrames: 30,
      x: resolutionX / 2,
      y: resolutionY / 2,
    } as const;
    this.createCardObjectAnimationDataParams = {
      startFrame: currentFrame,
      durationInFrames: 30,
      x: resolutionX / 2,
      y: resolutionY / 2,
    } as const;
  }

  createCardObject = <T extends CardObjectType["type"]>(
    type: T,
    customObjectName?: string,
  ): CreateCardObjectReturnType<T> => {
    let data;
    switch (type) {
      case "RAWTEXT":
        data = createCardObjectData(
          this.createCardObjectDataParams,
          RawTextOverrides,
        );
        break;
      case "RICHTEXT":
        data = createCardObjectData(
          this.createCardObjectDataParams,
          RichTextOverrides,
        );
        break;
      case "IMAGE":
        data = createCardObjectData(
          this.createCardObjectDataParams,
          ImageOverrides,
        );
        data.transform.scale = 100;
        break;
      case "VIDEO":
        data = createCardObjectData(
          this.createCardObjectDataParams,
          VideoOverrides,
        );
        break;
      case "AUDIO":
        data = createCardObjectData(
          this.createCardObjectDataParams,
          AudioOverrides,
        );
        break;
      case "CUSTOM": {
        switch (customObjectName) {
          case "FlowerExplosion":
            data = createCardObjectData(
              this.createCardObjectDataParams,
              RoseExplosionOverrides,
            );
            break;
          case "scatter":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              ScatterOverrides,
            );
            break;
          case "confettiPop":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              ConfettiPopOverrides,
            );
            break;
          case "bubbles":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              BubblesOverrides,
            );
            break;
          case "snow":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              SnowOverrides,
            );
            break;
          case "trail":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              TrailOverrides,
            );
            break;
          case "Watermark":
            data = createCardObjectData(
              this.createCardObjectAnimationDataParams,
              GiftWrapLogoOverrides,
            );
            break;
          default:
            throw new Error(
              `Custom Object Type ${customObjectName} is not a valid custom object type`,
            );
        }
      }
    }
    return data as CreateCardObjectReturnType<T>;
  };
}
