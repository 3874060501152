"use client";

import { useMemo, type CSSProperties } from "react";
import { type DeepReadonly } from "@/utils/tsmagic";
import { Sequence } from "remotion";
import { type z } from "zod";

import { type RawTextCardObjectSchema } from "../core/derivedSchema";
import { useCurrentFrameStyle } from "../core/useCurrentFrameStyle";

export type RawTextCardObject = z.infer<typeof RawTextCardObjectSchema>;
export type ReadOnlyRawTextCardObject = DeepReadonly<RawTextCardObject>;

export const RawTextCardObjectComponent: React.FC<{
  cardObject: ReadOnlyRawTextCardObject;
}> = ({ cardObject }) => {
  const { outerStyle, innerStyle } = useCurrentFrameStyle(cardObject);

  const style = useMemo(() => {
    const textStyle = {
      color: cardObject.metadata.style.color,
      backgroundColor: cardObject.metadata.style.bgColor,
      fontWeight: cardObject.metadata.style.bold ? "bold " : "",
      fontStyle: cardObject.metadata.style.italic ? "italic " : "",
      textDecoration: `${
        cardObject.metadata.style.underline ? "underline " : ""
      }
            ${cardObject.metadata.style.overline ? "overline " : ""}`,
      fontFamily: cardObject.metadata.style.font,
      whiteSpace: "nowrap",
    } satisfies CSSProperties;
    return textStyle;
  }, [
    cardObject.metadata.style.bgColor,
    cardObject.metadata.style.bold,
    cardObject.metadata.style.color,
    cardObject.metadata.style.font,
    cardObject.metadata.style.italic,
    cardObject.metadata.style.overline,
    cardObject.metadata.style.underline,
  ]);

  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <div style={outerStyle}>
        <div
          className="text-break text-wrap text-center"
          style={{ ...style, ...innerStyle }}
        >
          {cardObject.metadata.value}
        </div>
      </div>
    </Sequence>
  );
};
