import { type ReactElement } from "react";
import {
  AssetParticleMap,
  GetParticleAssetStaticUrlByName,
} from "@/remotion/assets/particleMap";
import { Img, random, useCurrentFrame } from "remotion";

import { type ReadonlyCustomCardObject } from "../CustomCardObject";
import { calculateXposWithDrag, calculateYposWithDrag } from "./particleUtils";

//trail ends of square to make wavy rectangle w respect to time
//rotate off OR based on velocity

export const DEFAULTS = {
  imgCount: 250,
  accelX: 0,
  accelY: 250,
  xBurstStrength: 700,
  yBurstStrength: 0,
  seed: 100,
  particleMass: 0.1,
  particleSize: 45,
  rotationSpeed: 2,
  lifespan: 30,
  airResistance: 2.4,
  angleSplice: 18,
  angleOfElevation: 56,
  opacity: 75,
  isFromSinglePoint: false,
  isFading: false,
  doRandomizeImages: false,
  isFixedParticleSize: false,
};

export const ConfettiPop: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }): ReactElement => {
  const frame = useCurrentFrame();
  return (
    <div>
      {[...Array(cardObject.metadata.imgCount || DEFAULTS.imgCount)].map(
        (_x, i) => {
          //USER MUTABLE VARS
          //create UI for these

          const seed = cardObject.metadata.seed || DEFAULTS.seed;

          const randomTimingSeed = random(seed * i * (i + i));
          const spreadSeed = random((i * seed + i) * (i * i) - i) - 0.5;
          const airResistanceSeed = random(-1 * seed * i - i);
          const thetaSeed = random(seed * i);
          const initialVelocitySeed = random(seed + i * i);
          const isFadingSeed = random(seed * i * i * i);
          const occilateSeed = random(seed * i * i * i * i * i);
          const scaleSeed = random(seed * i * (i - i) + i * i);
          const randomImgSeed = random(seed + frame + i);
          const nonRandomImgSeed = random(seed * (i * i) + i);
          const particleMassSeed = random(seed * (i + (i * i + i)));
          const rotateSeed = random(seed * i * i);

          const initialX = cardObject.transform.x; //+ (random(i) - 0.5) * 50;
          const initialY = cardObject.transform.y; //+ (random(-i) - 0.5) * 50;
          const particleMass =
            cardObject.metadata.particleMass || DEFAULTS.particleMass;
          const particleLifespan =
            cardObject.metadata.lifespan ?? DEFAULTS.lifespan;

          const randomParticleMass =
            particleMassSeed * (particleMass - particleMass * 0.8 + 1) +
            particleMass * 0.8;
          const opacity = cardObject.metadata.opacity ?? DEFAULTS.opacity;

          const airResistance =
            cardObject.metadata.airResistance ?? DEFAULTS.airResistance;
          const randomAirResistance =
            airResistanceSeed * (airResistance - airResistance * 0.95 + 1) +
            airResistance * 0.95;
          const gravity = cardObject.metadata.accelY || DEFAULTS.accelY; //gravity

          const spinStrength =
            cardObject.metadata.rotationSpeed || DEFAULTS.rotationSpeed;

          const pi = 3.1415;

          const animationRate = 1 / 30;
          const time = frame * animationRate;
          const duration = cardObject.sequence.durationInFrames;
          const size =
            cardObject.metadata.particleSize || DEFAULTS.particleSize;

          const angleOfElevation =
            cardObject.metadata.angleOfElevation || DEFAULTS.angleOfElevation;
          const angleSplice =
            cardObject.metadata.angleSplice || DEFAULTS.angleSplice;

          const theta = -angleOfElevation;
          const thetaSplice =
            thetaSeed * (theta + angleSplice - (theta - angleSplice) + 1) +
            (theta - angleSplice);

          const thetaInRadians = (thetaSplice * pi) / 180;

          const BurstStrength =
            (cardObject.metadata.xBurstStrength ?? DEFAULTS.xBurstStrength) /
            particleMass;

          const initialVelocity =
            initialVelocitySeed * (BurstStrength - BurstStrength * 0.1 + 1) +
            BurstStrength * 0.1;

          if (cardObject.metadata.isFading) {
            const lifespan =
              randomTimingSeed * (duration - duration / 2) + duration / 2;
            if (frame >= lifespan) {
              return null;
            }
          }

          const v0X = initialVelocity * Math.cos(thetaInRadians);
          const v0Y = initialVelocity * Math.sin(thetaInRadians);

          const x = calculateXposWithDrag(v0X, randomAirResistance, time);
          const y = calculateYposWithDrag(
            v0Y,
            -gravity,
            randomAirResistance,
            time,
          );

          const rotate =
            rotateSeed < 0.5
              ? rotateSeed * frame * spinStrength
              : -1 * rotateSeed * frame * spinStrength;
          const fixedSize = cardObject.metadata.isFixedParticleSize || false;
          const scale = fixedSize
            ? Math.exp(random(seed)) * size
            : Math.exp(scaleSeed) * size;

          //console.log(i, frame);
          function ParticleSelector() {
            const particleArray = cardObject.metadata.particleArray
              ? cardObject.metadata.particleArray
              : [AssetParticleMap["confetti_straight_b"].src];
            const userParticle = null;

            const combinedArray = Array.from(particleArray);
            if (userParticle != null) {
              //console.log(combinedArray);
              combinedArray.push(userParticle);
              //console.log(combinedArray);
            }
            //console.log(particleArray);
            //console.log(combinedArray);

            if (cardObject.metadata.doRandomizeImages === true) {
              const randomInt = Math.floor(
                combinedArray.length * randomImgSeed,
              );
              return combinedArray[randomInt];
            }
            const regularInt = Math.floor(
              combinedArray.length * nonRandomImgSeed,
            );
            return combinedArray[regularInt];
          }
          const src = GetParticleAssetStaticUrlByName(
            ParticleSelector() as string,
          );
          if (src !== undefined)
            return (
              <div
                key={i}
                style={{
                  zIndex: `${cardObject.transform.zIndex}`,
                  position: "absolute",
                  transform: `translate(${x + initialX}px,${y + initialY}px)`,
                }}
              >
                <Img
                  key={i}
                  src={src}
                  style={{
                    position: "relative",
                    transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
                    opacity: `${opacity / 100}`,
                  }}
                  width={scale}
                  height={scale}
                />
              </div>
            );
        },
      )}
    </div>
  );
};
