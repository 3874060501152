export function calculateXposWithDrag(
  v0X: number,
  airResistance: number,
  timeElapsed: number,
) {
  const x =
    (v0X / airResistance) * (1 - Math.exp(-1 * airResistance * timeElapsed));
  return x;
}

export function calculateYposWithDrag(
  v0Y: number,
  gravity: number,
  airResistance: number,
  timeElapsed: number,
) {
  const y =
    (1 / airResistance) *
    ((gravity / airResistance + v0Y) *
      (1 - Math.exp(-1 * airResistance * timeElapsed)) -
      gravity * timeElapsed);
  return y;
}

export function calculateXposWithoutDrag(v0X: number, timeElapsed: number) {
  const x = v0X * timeElapsed;
  return x;
}

export function calculateYposWithoutDrag(
  v0Y: number,
  gravity: number,
  timeElapsed: number,
) {
  const y = v0Y * timeElapsed - 0.5 * gravity * timeElapsed * timeElapsed;
  return y;
}

export function sinusoidalGenericRangeValue(
  time: number,
  value: number,
  valueRangePercent: number,
) {
  const maxValue = value + (value * valueRangePercent) / 100;
  const minValue = value - (value * valueRangePercent) / 100;
  const clampValue =
    (Math.sin(time) + 1) * 0.5 * (maxValue - minValue + 1) + minValue;
  //console.log((Math.sin(time) + 1) / 2);
  return clampValue;
}

export function degToRad(degrees: number) {
  return (degrees * Math.PI) / 180;
}

export function radToDeg(rad: number) {
  return (rad * 180.0) / Math.PI;
}

export function splitmix32(a: number) {
  return function () {
    a |= 0;
    a = (a + 0x9e3779b9) | 0;
    let t = a ^ (a >>> 16);
    t = Math.imul(t, 0x21f0aaad);
    t = t ^ (t >>> 15);
    t = Math.imul(t, 0x735a2d97);
    return ((t = t ^ (t >>> 15)) >>> 0) / 4294967296;
  };
}

/**
 *
 * @param scaler number between 0 and 1
 * @param value any number
 * @param percentScaledDecimal number between 0 and 1
 * @returns
 */
export function boundedPercentInRange(
  scaler: number,
  baseValue: number,
  percentScaledDecimal: number,
) {
  return (
    baseValue * (1 - percentScaledDecimal) +
    scaler * baseValue * percentScaledDecimal
  );
}
