"use client";

import { type DeepReadonly } from "@/utils/tsmagic";
import { Audio, Sequence } from "remotion";
import { type z } from "zod";

import { useRemotionAsset } from "../core/AssetManager";
import { type AudioCardObjectSchema } from "../core/derivedSchema";

export type AudioCardObject = z.infer<typeof AudioCardObjectSchema>;

export type ReadonlyAudioCardObject = DeepReadonly<AudioCardObject>;

export const AudioCardObjectComponent: React.FC<{
  cardObject: ReadonlyAudioCardObject;
}> = ({ cardObject }) => {
  const src = useRemotionAsset(cardObject.metadata.audioSrcUrl || "");

  if (src === undefined) return;

  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <Audio
        src={src}
        volume={cardObject.metadata.volume / 100}
        playbackRate={cardObject.metadata.playbackRate}
        startFrom={(30 * cardObject.metadata.startFrom) / 1000}
      />
    </Sequence>
  );
};
