import React from "react";

/**
 * This hook guarentees that the provided function is only calculated once.
 * Use for expensive computation
 * @param fn
 * @returns
 */
export function useConstant<T>(fn: () => T) {
  const ref = React.useRef<{ v: T }>();

  if (!ref.current) {
    ref.current = {
      v: fn(),
    };
  }

  return ref.current.v;
}
