import { type ReactElement } from "react";
import {
  AssetParticleMap,
  GetParticleAssetStaticUrlByName,
} from "@/remotion/assets/particleMap";
import { Img, random, useCurrentFrame } from "remotion";

import { type ReadonlyCustomCardObject } from "../CustomCardObject";
import { calculateXposWithDrag, calculateYposWithDrag } from "./particleUtils";

//trail ends of square to make wavy rectangle w respect to time
//rotate off OR based on velocity
export const DEFAULTS = {
  imgCount: 200,

  accelX: 0,
  accelY: 100,
  xBurstStrength: 220,
  yBurstStrength: 0,

  seed: 10,

  particleMass: 0.2,
  particleSize: 60,
  rotationSpeed: 50,
  lifespan: 30,
  //,spread : 250

  airResistance: 1.8,
  opacity: 75,

  //,angleSplice : 1
  //,angleOfElevation : 1

  //,isFromSinglePoint : false
  isFading: true,
  doRandomizeImages: true,
  isFixedParticleSize: false,
};

export const Scatter_OLD: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }): ReactElement => {
  const frame = useCurrentFrame();

  //defaults + user mutable vals
  const pi = 3.1415;

  const initialX = cardObject.transform.x; //+ (random(i) - 0.5) * 50;
  const initialY = cardObject.transform.y; //+ (random(-i) - 0.5) * 50;
  const particleMass =
    cardObject.metadata.particleMass ?? DEFAULTS.particleMass;
  const airResistance =
    cardObject.metadata.airResistance ?? DEFAULTS.airResistance;
  const gravity = cardObject.metadata.accelY ?? DEFAULTS.accelY; //gravity
  const imgCount = cardObject.metadata.imgCount ?? DEFAULTS.imgCount;
  const spinStrength =
    cardObject.metadata.rotationSpeed ?? DEFAULTS.rotationSpeed;
  const BurstStrength =
    cardObject.metadata.xBurstStrength ?? DEFAULTS.xBurstStrength;
  const size = cardObject.metadata.particleSize ?? DEFAULTS.particleSize;
  const seed = cardObject.metadata.seed ?? DEFAULTS.seed;

  const particleLifespan = cardObject.metadata.lifespan ?? DEFAULTS.lifespan;
  const opacity = cardObject.metadata.opacity ?? DEFAULTS.opacity;

  const animationRate = 1 / 30;
  const time = frame * animationRate;
  const duration = cardObject.sequence.durationInFrames;

  const radius = 5;

  return (
    <div>
      {[...Array(imgCount)].map((_x, i) => {
        const randomTimingSeed = random(seed * i * (i + i));
        const spreadSeed = random((i * seed + i) * (i * i) - i) - 0.5;
        const airResistanceSeed = random(seed * i * i * i * i * i * i);
        const thetaSeed = random(seed * i);

        const initialVelocitySeed = random((seed + i) * (i * i * i - i) * i);
        const isFadingSeed = random(seed * i * i * i);
        const occilateSeed = random(seed * i * i * i * i * i);
        const scaleSeed = random(seed * i * i * i * i);
        const randomImgSeed = random(seed + frame + i);
        const nonRandomImgSeed = random(seed * (i * i) + i);
        const particleMassSeed = random((seed - i) * (i * (i * i + i) - i));
        const rotateSeed = random(seed * i * i);

        if (cardObject.metadata.isFading) {
          const particleLifespan =
            isFadingSeed * (duration - duration / 2) + duration / 2;
          if (frame >= particleLifespan) {
            return null;
          }
        }
        const theta = thetaSeed * 360;
        const thetaInRadians = (theta * pi) / 180;
        const initialVelocity =
          initialVelocitySeed * (BurstStrength - BurstStrength * 0.1) +
          BurstStrength * 0.1;
        const randomParticleMass =
          particleMassSeed * (particleMass - particleMass * 0.95 + 1) +
          particleMass * 0.95;
        const randomAirResistance =
          airResistanceSeed * (airResistance - airResistance * 0.9) +
          airResistance * 0.9;
        const opacityVal = opacity / 100;

        const v0X = (initialVelocity / particleMass) * Math.cos(thetaInRadians);
        const v0Y = (initialVelocity / particleMass) * Math.sin(thetaInRadians);

        const x = calculateXposWithDrag(v0X, randomAirResistance, time); //calculateXpos(v0X, randomAirResistance);
        const y = calculateYposWithDrag(
          v0Y,
          -gravity,
          randomAirResistance,
          time,
        );

        const rotate =
          rotateSeed < 0.5
            ? -1 * rotateSeed * time * spinStrength
            : rotateSeed * time * spinStrength;
        const fixedSize =
          cardObject.metadata.isFixedParticleSize ??
          DEFAULTS.isFixedParticleSize;

        const scale = fixedSize ? size : Math.exp(scaleSeed) * size;

        //console.log(i, frame);
        function ParticleSelector() {
          const particleArray = cardObject.metadata.particleArray
            ? cardObject.metadata.particleArray
            : [AssetParticleMap["fireworks_particle_dot_b"].src];
          const userParticle = null;

          const combinedArray = Array.from(particleArray);
          if (userParticle != null) {
            //console.log(combinedArray);
            combinedArray.push(userParticle);
            //console.log(combinedArray);
          }
          //console.log(particleArray);
          //console.log(combinedArray);

          if (cardObject.metadata.doRandomizeImages == true) {
            const randomInt = Math.floor(combinedArray.length * randomImgSeed);
            return combinedArray[randomInt];
          }
          const regularInt = Math.floor(
            combinedArray.length * nonRandomImgSeed,
          );
          return combinedArray[regularInt];
        }
        const src = GetParticleAssetStaticUrlByName(
          ParticleSelector() as string,
        );
        if (src !== undefined)
          return (
            <div
              key={i}
              style={{
                zIndex: `${cardObject.transform.zIndex}`,
                position: "absolute",
                transform: `translate(${x + initialX}px,${y + initialY}px)`,
              }}
            >
              <Img
                key={i}
                src={src}
                style={{
                  position: "relative",
                  transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
                  opacity: `${opacityVal}`,
                }}
                width={scale}
                height={scale}
              />
            </div>
          );
      })}
    </div>
  );
};
