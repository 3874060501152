"use client";

import { type DeepReadonly } from "@/utils/tsmagic";
import { Sequence, Video } from "remotion";
import { type z } from "zod";

import { useRemotionAsset } from "../core/AssetManager";
import { type VideoCardObjectSchema } from "../core/derivedSchema";
import { useCurrentFrameStyle } from "../core/useCurrentFrameStyle";

export type VideoCardObject = z.infer<typeof VideoCardObjectSchema>;
export type ReadonlyVideoCardObject = DeepReadonly<VideoCardObject>;

export const VideoCardObjectComponent: React.FC<{
  cardObject: ReadonlyVideoCardObject;
}> = ({ cardObject }) => {
  switch (cardObject.version) {
    case 0:
      return <V0 cardObject={cardObject} key={cardObject.id} />;
    case 1:
      return <V1 cardObject={cardObject} key={cardObject.id} />;
  }
};

const V0: React.FC<{
  cardObject: ReadonlyVideoCardObject;
}> = ({ cardObject }) => {
  const src = useRemotionAsset(cardObject.metadata.assetURL);
  if (src === undefined) return null;

  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <Video
        style={{
          zIndex: cardObject.transform.zIndex,
        }}
        volume={cardObject.metadata.volume / 100}
        src={src}
        className="h-[100%] w-[100%] object-cover"
        onError={(event) => {
          event.currentTarget.onerror = null;
          event.currentTarget.style.display = "none";
        }}
      />
    </Sequence>
  );
};

const V1: React.FC<{
  cardObject: ReadonlyVideoCardObject;
}> = ({ cardObject }) => {
  const src = useRemotionAsset(cardObject.metadata.assetURL);
  const { outerStyle, innerStyle } = useCurrentFrameStyle(cardObject);
  if (src === undefined) return null;
  return (
    <Sequence
      from={cardObject.sequence.from}
      durationInFrames={cardObject.sequence.durationInFrames}
      layout="none"
    >
      <div style={outerStyle}>
        <Video
          style={innerStyle}
          volume={cardObject.metadata.volume / 100}
          src={src}
          onError={(event) => {
            event.currentTarget.onerror = null;
            event.currentTarget.style.display = "none";
          }}
        />
      </div>
    </Sequence>
  );
};
