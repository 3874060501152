"use client";

import React, { createRef, type ComponentProps } from "react";
import { type DeepReadonly } from "@/utils/tsmagic";
import { Player, type PlayerRef } from "@remotion/player";
import { proxy, useSnapshot } from "valtio";

import { type WrapVideo } from "@matan/database";

import { type ReadOnlyCardObjects } from "./core/derivedSchema";
import RootComposition from "./RootComposition";

type Props = Partial<ComponentProps<typeof Player>> & {
  readonly wrapVideo: DeepReadonly<WrapVideo>;
  readonly cardObjects: ReadOnlyCardObjects;
  withEndplate?: boolean;
  clickToPlay?: boolean;
};

export const RemotionPlayerRef = proxy(createRef<PlayerRef>());
export const usePlayerRef = () => useSnapshot(RemotionPlayerRef);

const RemotionPlayer = ({
  wrapVideo,
  cardObjects,
  withEndplate,
  ...props
}: Props) => {
  const durationInFrames = withEndplate
    ? wrapVideo.durationInFrames + 60
    : wrapVideo.durationInFrames;

  const inputProps = React.useMemo(() => {
    return {
      cardId: undefined,
      data: {
        cardObjects,
        wrapVideo,
      },
      withEndplate,
    };
  }, [cardObjects, withEndplate, wrapVideo]);

  return (
    <Player
      {...props}
      ref={RemotionPlayerRef}
      inputProps={inputProps}
      component={RootComposition}
      durationInFrames={durationInFrames}
      compositionWidth={wrapVideo.resolutionX}
      compositionHeight={wrapVideo.resolutionY}
      fps={wrapVideo.fps}
      className="max-h-full rounded-lg bg-white shadow-sm lg:grow"
      moveToBeginningWhenEnded={false}
    />
  );
};

export const RemotionPlayerPreview = ({
  wrapVideo,
  cardObjects,
  style,
  autoPlay,
  controls,
  loop,
}: Props) => {
  const inputProps = React.useMemo(() => {
    return {
      cardId: undefined,
      data: {
        cardObjects,
        wrapVideo,
      },
    };
  }, [cardObjects, wrapVideo]);

  return (
    <Player
      inputProps={inputProps}
      initiallyMuted
      component={RootComposition}
      durationInFrames={wrapVideo.durationInFrames}
      compositionWidth={wrapVideo.resolutionX}
      compositionHeight={wrapVideo.resolutionY}
      fps={wrapVideo.fps}
      className="max-h-full rounded-lg bg-white shadow-sm lg:grow"
      style={style}
      loop={loop}
      moveToBeginningWhenEnded={false}
      autoPlay={autoPlay}
      controls={controls}
    />
  );
};

export default RemotionPlayer;
