"use client";

import { useMemo } from "react";
import { useCurrentFrame } from "remotion";

import { AnimateTransform } from "./animations";
import { type ReadOnlyCardObject } from "./derivedSchema";

const FPS = 30 as const;

export const useCurrentFrameStyle = (cardObject: ReadOnlyCardObject) => {
  const currentFrame = useCurrentFrame();
  return useMemo(() => {
    const objTransform = AnimateTransform({
      transform: cardObject.transform,
      animations: cardObject.animations,
      currentFrame,
      fps: FPS,
    });
    const outerStyle: React.CSSProperties = {
      position: "absolute",
      transform: `translate(${objTransform.x}px, ${objTransform.y}px)`,
      zIndex: objTransform.zIndex,
      userSelect: "none",
    };

    const innerStyle: React.CSSProperties = {
      transform: `translate(-50%, -50%)`,
      rotate: `${objTransform.rotate}deg`,
      scale: `${objTransform.scale / 100}`,
      transformOrigin: "top left",
    };
    return { outerStyle, innerStyle };
  }, [cardObject.animations, cardObject.transform, currentFrame]);
};
