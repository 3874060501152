import { type ReactElement } from "react";
import {
  AssetParticleMap,
  GetParticleAssetStaticUrlByName,
} from "@/remotion/assets/particleMap";
import { Img, random, useCurrentFrame } from "remotion";

import { type ReadonlyCustomCardObject } from "../CustomCardObject";
import {
  calculateXposWithDrag,
  calculateYposWithDrag,
  sinusoidalGenericRangeValue,
} from "./particleUtils";

//trail ends of square to make wavy rectangle w respect to time
//rotate off OR based on velocity
export const DEFAULTS = {
  imgCount: 150,
  accelX: 0,
  accelY: 220,
  xBurstStrength: -240,
  yBurstStrength: 0,
  seed: 10,
  particleMass: 0.75,
  particleSize: 125,
  rotationSpeed: 3,
  spread: 1000,
  opacity: 85,
  airResistance: 0.25,
  angleSplice: 50,
  angleOfElevation: 90,
  scaleFrequency: 0.5,
  contrastFrequency: 4.0,
  invertFrequency: 5,
  brightnessFrequency: 0.5,
  scaleRangePercent: 30,
  contrastRangePercent: 25,
  invertRangePercent: 10,
  brightnessRangePercent: 10,
  isFromSinglePoint: false,
  isFading: false,
  doRandomizeImages: false,
  isFixedParticleSize: false,
};

export const Snow: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }): ReactElement => {
  const frame = useCurrentFrame();
  return (
    <div>
      {[...Array(cardObject.metadata.imgCount || DEFAULTS.imgCount)].map(
        (_x, i) => {
          //USER MUTABLE VARS
          //create UI for these
          const seed = cardObject.metadata.seed || DEFAULTS.seed;
          const minFrames = cardObject.sequence.durationInFrames * 0.1;

          const randomTimingSeed = random(seed * i * (i + i));
          const spreadSeed = random((i * seed + i) * (i * i) - i) - 0.5;
          // const airResistanceSeed = random(
          //   seed * i * (i * i + i) * (i * i + i) * i,
          // );
          const airResistanceSeed = random(seed * i * i * i * i);
          const thetaSeed = random(seed * i);
          const initialVelocitySeed = random((seed + i) * i);
          const isFadingSeed = random(seed * i * i * i);
          const occilateSeed = random(seed * i * i * i * i * i);
          const scaleSeed = random(seed * i * (i * i + i) * (i * i + i) * i);
          const randomImgSeed = random(seed + frame + i);
          const nonRandomImgSeed = random(seed * (i * i) + i);
          const particleMassSeed = random(seed * (i + (i * i + i)));

          const particleStartTime = Math.floor(
            randomTimingSeed * (cardObject.sequence.durationInFrames - 0 + 1) +
              0,
          );

          if (frame < particleStartTime) {
            return null;
          }
          const scaleFrequency =
            cardObject.metadata.scaleFrequency ?? DEFAULTS.scaleFrequency;
          const invertFrequency =
            cardObject.metadata.invertFrequency ?? DEFAULTS.invertFrequency;
          const contrastFrequency =
            cardObject.metadata.contrastFrequency ?? DEFAULTS.contrastFrequency;
          const brightnessFrequency =
            cardObject.metadata.brightnessFrequency ??
            DEFAULTS.brightnessFrequency;

          const brightnessRangePercent =
            cardObject.metadata.brightnessRangePercent ??
            DEFAULTS.brightnessRangePercent;
          const scaleRangePercent =
            cardObject.metadata.scaleRangePercent ?? DEFAULTS.scaleRangePercent;
          const contrastRangePercent =
            cardObject.metadata.contrastRangePercent ??
            DEFAULTS.contrastRangePercent;
          const invertRangePercent =
            cardObject.metadata.invertRangePercent ??
            DEFAULTS.invertRangePercent;

          const startFromPoint =
            cardObject.metadata.isFromSinglePoint || DEFAULTS.isFromSinglePoint;

          const initialX = startFromPoint
            ? cardObject.transform.x
            : cardObject.transform.x +
              spreadSeed * (cardObject.metadata.spread || DEFAULTS.spread);
          const initialY = cardObject.transform.y; //+ (random(-i) - 0.5) * 50;

          const particleMass =
            cardObject.metadata.particleMass || DEFAULTS.particleMass;

          const randomParticleMass =
            particleMassSeed * (particleMass - particleMass * 0.8 + 1) +
            particleMass * 0.8;

          const gravity = cardObject.metadata.accelY || DEFAULTS.accelY; //gravity
          //console.log(gravity, "gravity for cardObject", cardObject.id);
          const xSpread = 10;
          const ySpread = 10;

          const spinStrength =
            cardObject.metadata.rotationSpeed || DEFAULTS.rotationSpeed;

          const pi = 3.1415;

          const animationRate = 1 / 30;
          const time = (frame - particleStartTime) * animationRate;
          //console.log(time);
          // if (true) {
          //   console.log(time, "time");
          //   console.log(frame, "frame");
          //   console.log(randomTiming, "randomTiming");
          //   console.log(i, "index");
          // }
          const duration = cardObject.sequence.durationInFrames;
          const fixedSize =
            cardObject.metadata.isFixedParticleSize ||
            DEFAULTS.isFixedParticleSize;

          const radius = 5;
          const size =
            cardObject.metadata.particleSize || DEFAULTS.particleSize;

          const airResistance =
            (cardObject.metadata.airResistance || DEFAULTS.airResistance) *
              airResistanceSeed *
              (1 - 0.5 + 1) +
            0.5;

          const angleOfElevation =
            cardObject.metadata.angleOfElevation || DEFAULTS.angleOfElevation;
          const angleSplice =
            cardObject.metadata.angleSplice || DEFAULTS.angleSplice;

          const theta = -angleOfElevation;
          const thetaSplice =
            thetaSeed * (theta + angleSplice - (theta - angleSplice) + 1) +
            (theta - angleSplice);
          const thetaInRadians = (thetaSplice * pi) / 180;

          const BurstStrength =
            cardObject.metadata.xBurstStrength || DEFAULTS.xBurstStrength;

          const initialVelocity =
            initialVelocitySeed * (BurstStrength - BurstStrength * 0.5) +
            BurstStrength * 0.5;
          const particleLifespan =
            isFadingSeed * (time - time * 0.1 + 1) + time * 0.1;

          let opacity = (cardObject.metadata.opacity ?? DEFAULTS.opacity) / 100;
          //console.log("opacity: ", opacity);
          const isFading = cardObject.metadata.isFading ?? DEFAULTS.isFading;
          if (isFading) {
            if (time >= particleLifespan) {
              opacity = opacity - time / particleLifespan;
              //return null;
            }
          }

          const v0X = initialVelocity * Math.cos(thetaInRadians);
          const v0Y = initialVelocity * Math.sin(thetaInRadians);

          const x = calculateXposWithDrag(v0X, airResistance, time);
          const y = calculateYposWithDrag(v0Y, -gravity, airResistance, time);

          function ParticleSelector() {
            const particleArray = cardObject.metadata.particleArray
              ? cardObject.metadata.particleArray
              : [AssetParticleMap["fireworks_particle_sparkle_b"].src];
            const userParticle = null;

            const combinedArray = Array.from(particleArray);
            if (userParticle != null) {
              //console.log(combinedArray);
              combinedArray.push(userParticle);
              //console.log(combinedArray);
            }
            //console.log(particleArray);
            //console.log(combinedArray);

            if (cardObject.metadata.doRandomizeImages == true) {
              const randomInt = Math.floor(
                combinedArray.length * randomImgSeed,
              );
              //add smoother transition here for assets
              switch (false) {
                case randomInt <= 0.33:
                case randomInt > 0.33 && randomInt <= 0.66:
                default:
              }
              return combinedArray[randomInt];
            } else {
              const regularInt = Math.floor(
                combinedArray.length * nonRandomImgSeed,
              );
              return combinedArray[regularInt];
            }
          }
          //10's below are to dramatize time offset for individuality
          const scaleValue = fixedSize
            ? (random(seed) * (1 - 0.9 + 1) + 0.9) * size
            : sinusoidalGenericRangeValue(
                (time + random(seed * i) * 10) * scaleFrequency,
                (thetaSeed * (1 - 0.75) + 0.75) * size,
                scaleRangePercent,
              );
          const brightnessValue = sinusoidalGenericRangeValue(
            (time + spreadSeed * 1000) * brightnessFrequency,
            1.5,
            brightnessRangePercent,
          );
          //console.log(brightnessValue);
          const contrastValue = sinusoidalGenericRangeValue(
            (time + spreadSeed * 5) * contrastFrequency,
            1.5,
            contrastRangePercent,
          );
          const invertValue = sinusoidalGenericRangeValue(0, 0.5, 50);
          const src = GetParticleAssetStaticUrlByName(
            ParticleSelector() as string,
          );
          if (src !== undefined)
            return (
              <div
                key={i}
                style={{
                  zIndex: `${cardObject.transform.zIndex}`,
                  position: "absolute",
                  transform: `translate(${x + initialX}px,${y + initialY}px)`,
                }}
              >
                <Img
                  key={i}
                  src={src}
                  style={{
                    position: "relative",
                    transform: `translate(-50%, -50%) rotate(${0}deg)`,
                    opacity: `${opacity}`,
                    filter:
                      "contrast(" +
                      contrastValue.toString() +
                      ") brightness(" +
                      brightnessValue.toString() +
                      ") ",
                  }}
                  width={scaleValue}
                  height={scaleValue}
                />
              </div>
            );
        },
      )}
    </div>
  );
};
