import confetti_curve_b from "public/images/particles/confetti_curve_b.png";
import confetti_curve_g from "public/images/particles/confetti_curve_g.png";
import confetti_curve_r from "public/images/particles/confetti_curve_r.png";
import confetti_straight_b from "public/images/particles/confetti_straight_b.png";
import confetti_straight_g from "public/images/particles/confetti_straight_g.png";
import confetti_straight_r from "public/images/particles/confetti_straight_r.png";
import confetti_swirly_b from "public/images/particles/confetti_swirly_b.png";
import confetti_swirly_g from "public/images/particles/confetti_swirly_g.png";
import confetti_swirly_r from "public/images/particles/confetti_swirly_r.png";
import fireworks_particle_1 from "public/images/particles/fireworks_particle_1.png";
import fireworks_particle_2 from "public/images/particles/fireworks_particle_2.png";
import fireworks_particle_3 from "public/images/particles/fireworks_particle_3.png";
import fireworks_particle_4 from "public/images/particles/fireworks_particle_4.png";
import fireworks_particle_dot_b from "public/images/particles/fireworks_particle_dot_b.png";
import fireworks_particle_dot_g from "public/images/particles/fireworks_particle_dot_g.png";
import fireworks_particle_dot_r from "public/images/particles/fireworks_particle_dot_r.png";
import fireworks_particle_eight_dots_b from "public/images/particles/fireworks_particle_eight_dots_b.png";
import fireworks_particle_eight_dots_g from "public/images/particles/fireworks_particle_eight_dots_g.png";
import fireworks_particle_eight_dots_r from "public/images/particles/fireworks_particle_eight_dots_r.png";
import fireworks_particle_mini_firework_b from "public/images/particles/fireworks_particle_mini_firework_b.png";
import fireworks_particle_mini_firework_g from "public/images/particles/fireworks_particle_mini_firework_g.png";
import fireworks_particle_mini_firework_r from "public/images/particles/fireworks_particle_mini_firework_r.png";
import fireworks_particle_sparkle_b from "public/images/particles/fireworks_particle_sparkle_b.png";
import fireworks_particle_sparkle_g from "public/images/particles/fireworks_particle_sparkle_g.png";
import fireworks_particle_sparkle_r from "public/images/particles/fireworks_particle_sparkle_r.png";
import isaacF_bubble_purpleblue from "public/images/particles/isaacF-bubble-purpleblue.png";
import isaacf_bubble_purplebluesquash from "public/images/particles/isaacf-bubble-purplebluesquash.png";
import isaacf_bubble_purplebluestretch from "public/images/particles/isaacf-bubble-purplebluestretch.png";
import isaacf_particle_snow_flake1 from "public/images/particles/isaacf-particle-snow-flake1.png";
import isaacf_particle_snow_flake2 from "public/images/particles/isaacf-particle-snow-flake2.png";
import isaacf_smoke_singlepuff from "public/images/particles/isaacf-smoke-singlepuff.png";
import redX from "public/images/particles/redX.png";
import trailAsset from "public/images/particles/trailAsset.png";

export const AssetParticleMap = {
  confetti_curve_b,
  confetti_curve_g,
  confetti_curve_r,
  confetti_straight_b,
  confetti_straight_g,
  confetti_straight_r,
  confetti_swirly_b,
  confetti_swirly_g,
  confetti_swirly_r,
  fireworks_particle_1,
  fireworks_particle_2,
  fireworks_particle_3,
  fireworks_particle_4,
  fireworks_particle_dot_b,
  fireworks_particle_dot_g,
  fireworks_particle_dot_r,
  fireworks_particle_eight_dots_b,
  fireworks_particle_eight_dots_g,
  fireworks_particle_eight_dots_r,
  fireworks_particle_mini_firework_b,
  fireworks_particle_mini_firework_g,
  fireworks_particle_mini_firework_r,
  fireworks_particle_sparkle_b,
  fireworks_particle_sparkle_g,
  fireworks_particle_sparkle_r,
  redX,
  isaacF_bubble_purpleblue,
  isaacf_bubble_purplebluesquash,
  isaacf_bubble_purplebluestretch,
  isaacf_particle_snow_flake1,
  isaacf_particle_snow_flake2,
  isaacf_smoke_singlepuff,
  trailAsset,
} as const;

export type ParticleName = keyof typeof AssetParticleMap;

export function GetParticleAssetStaticUrlByName(name: string) {
  if (name in AssetParticleMap) {
    return AssetParticleMap[name as ParticleName].src;
  }
}
