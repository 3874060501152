"use client";

import { spring } from "remotion";

import { type AnimationType, type TransformType } from "./baseSchema";
import { type ReadOnlyCardObject } from "./derivedSchema";

type AnimateTransformParams = {
  transform: TransformType;
  animations: ReadOnlyCardObject["animations"];
  currentFrame: number;
  fps: number;
};
type AnimateFunctionParams = {
  transform: TransformType;
  animation: AnimationType;
  currentFrame: number;
  fps: number;
};

export function AnimateTransform({
  transform,
  animations,
  currentFrame,
  fps,
}: AnimateTransformParams): TransformType {
  // Filter to animations taking place in the currentFrame
  const currentAnimations = animations.filter(
    (item) =>
      item.startFrame <= currentFrame &&
      currentFrame <= item.startFrame + item.durationInFrames,
  );
  // Apply each ongoing animation to the cardObject transform
  let newTransform = JSON.parse(JSON.stringify(transform)) as typeof transform;

  currentAnimations.forEach((animation) => {
    const params = { transform: newTransform, animation, currentFrame, fps };
    switch (animation.name) {
      case "zoomIn":
        newTransform = zoomIn(params);
        break;
      case "zoomOut":
        newTransform = zoomOut(params);
        break;
    }
  });
  return newTransform;
}

function zoomIn(params: AnimateFunctionParams): TransformType {
  const springValue = spring({
    frame: params.currentFrame - params.animation.startFrame,
    fps: params.fps,
    config: {
      stiffness: 200,
      mass: 0.5,
    },
    durationInFrames: params.animation.durationInFrames,
  });
  return {
    ...params.transform,
    scale: params.transform.scale * springValue,
  };
}

function zoomOut(params: AnimateFunctionParams): TransformType {
  const springValue = spring({
    frame: params.currentFrame - params.animation.startFrame,
    fps: params.fps,
    config: {
      stiffness: 200,
      mass: 0.5,
    },
    from: 1,
    to: 0,
    durationInFrames: params.animation.durationInFrames,
  });
  return {
    ...params.transform,
    scale: params.transform.scale * springValue,
  };
}
