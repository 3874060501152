import { type ReactElement } from "react";
import {
  AssetParticleMap,
  GetParticleAssetStaticUrlByName,
} from "@/remotion/assets/particleMap";
import { Img, random, useCurrentFrame } from "remotion";

import { type ReadonlyCustomCardObject } from "../CustomCardObject";
import { calculateXposWithDrag, calculateYposWithDrag } from "./particleUtils";

//trail ends of square to make wavy rectangle w respect to time
//rotate off OR based on velocity

export const DEFAULTS = {
  imgCount: 200,
  accelX: 0,
  accelY: -180,
  xBurstStrength: 350,
  yBurstStrength: 0,
  seed: 10,
  particleMass: 0.75,
  particleSize: 125,
  rotationSpeed: 3,
  spread: 500,
  opacity: 100,
  airResistance: 0.25,
  angleSplice: 50,
  angleOfElevation: 90,
  isFromSinglePoint: false,
  isFading: false,
  doRandomizeImages: false,
  isFixedParticleSize: false,
};

export const Bubbles: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }): ReactElement => {
  const frame = useCurrentFrame();
  return (
    <div>
      {[...Array(cardObject.metadata.imgCount || DEFAULTS.imgCount)].map(
        (_x, i) => {
          //USER MUTABLE VARS
          //create UI for these
          const seed = cardObject.metadata.seed || DEFAULTS.seed;
          const minFrames = cardObject.sequence.durationInFrames * 0.1;

          const randomTimingSeed = random(seed * i * (i + i));
          const spreadSeed = random((i * seed + i) * (i * i) - i) - 0.5;
          // const airResistanceSeed = random(
          //   seed * i * (i * i + i) * (i * i + i) * i,
          // );
          const airResistanceSeed = random(seed * i * i * i * i);
          const thetaSeed = random(seed * i);
          const initialVelocitySeed = random((seed + i) * i);
          const isFadingSeed = random(seed * i * i * i);
          const occilateSeed = random(seed * i * i * i * i * i);
          const scaleSeed = random(seed * i * (i * i + i) * (i * i + i) * i);
          const randomImgSeed = random(seed + frame + i);
          const nonRandomImgSeed = random(seed * (i * i) + i);
          const particleMassSeed = random(seed * (i + (i * i + i)));

          const particleStartTime = Math.floor(
            randomTimingSeed * (cardObject.sequence.durationInFrames - 0 + 1) +
              0,
          );

          if (frame < particleStartTime) {
            return null;
          }
          const startFromPoint =
            cardObject.metadata.isFromSinglePoint || DEFAULTS.isFromSinglePoint;

          const initialX = startFromPoint
            ? cardObject.transform.x
            : cardObject.transform.x +
              spreadSeed * (cardObject.metadata.spread || DEFAULTS.spread);
          const initialY = cardObject.transform.y; //+ (random(-i) - 0.5) * 50;

          const particleMass =
            cardObject.metadata.particleMass || DEFAULTS.particleMass;

          const randomParticleMass =
            particleMassSeed * (particleMass - particleMass * 0.8 + 1) +
            particleMass * 0.8;

          const gravity = cardObject.metadata.accelY || DEFAULTS.accelY; //gravity
          //console.log(gravity, "gravity for cardObject", cardObject.id);
          const xSpread = 10;
          const ySpread = 10;

          const spinStrength =
            cardObject.metadata.rotationSpeed || DEFAULTS.rotationSpeed;

          const pi = 3.1415;

          const animationRate = 1 / 30;
          const time = (frame - particleStartTime) * animationRate;
          // if (true) {
          //   console.log(time, "time");
          //   console.log(frame, "frame");
          //   console.log(randomTiming, "randomTiming");
          //   console.log(i, "index");
          // }
          const duration = cardObject.sequence.durationInFrames;
          const fixedSize =
            cardObject.metadata.isFixedParticleSize ||
            DEFAULTS.isFixedParticleSize;

          const radius = 5;
          const size =
            cardObject.metadata.particleSize || DEFAULTS.particleSize;

          const airResistance =
            (cardObject.metadata.airResistance || DEFAULTS.airResistance) *
              airResistanceSeed *
              (1 - 0.5 + 1) +
            0.5;

          const angleOfElevation =
            cardObject.metadata.angleOfElevation || DEFAULTS.angleOfElevation;
          const angleSplice =
            cardObject.metadata.angleSplice || DEFAULTS.angleSplice;

          const theta = -angleOfElevation;
          const thetaSplice =
            thetaSeed * (theta + angleSplice - (theta - angleSplice) + 1) +
            (theta - angleSplice);
          const thetaInRadians = (thetaSplice * pi) / 180;

          const BurstStrength =
            cardObject.metadata.xBurstStrength || DEFAULTS.xBurstStrength;

          const initialVelocity =
            initialVelocitySeed * (BurstStrength - BurstStrength * 0.5) +
            BurstStrength * 0.5;
          const particleLifespan =
            isFadingSeed * (time - time * 0.1 + 1) + time * 0.1;

          let opacity = (cardObject.metadata.opacity ?? DEFAULTS.opacity) / 100;
          //console.log("opacity: ", opacity);
          const isFading = cardObject.metadata.isFading ?? DEFAULTS.isFading;
          if (isFading) {
            if (time >= particleLifespan) {
              opacity = opacity - time / particleLifespan;
              //return null;
            }
          }

          const v0X = initialVelocity * Math.cos(thetaInRadians);
          const v0Y = initialVelocity * Math.sin(thetaInRadians);

          const occilate = Math.sin(
            time *
              (occilateSeed * (spinStrength - spinStrength * 0.2 + 1) +
                spinStrength * 0.2),
          );

          const x = calculateXposWithDrag(v0X, airResistance, time);
          const y = calculateYposWithDrag(v0Y, -gravity, airResistance, time);

          // const x = 0.5 * accelX * time * time + ((v0X)) * time + initialX;
          // const y = 0.5 * accelY * time * time + v0Y * time + initialY;

          const scale = fixedSize
            ? (random(seed) * (1 - 0.9 + 1) + 0.9) * size
            : (scaleSeed * (1 - 0.75) + 0.75) * size;

          function ParticleSelector() {
            const particleArray = cardObject.metadata.particleArray
              ? cardObject.metadata.particleArray
              : [AssetParticleMap["confetti_curve_b"].src];
            const userParticle = null;

            const combinedArray = Array.from(particleArray);
            if (userParticle != null) {
              //console.log(combinedArray);
              combinedArray.push(userParticle);
              //console.log(combinedArray);
            }
            //console.log(particleArray);
            //console.log(combinedArray);

            if (cardObject.metadata.doRandomizeImages == true) {
              const randomInt = Math.floor(
                combinedArray.length * randomImgSeed,
              );
              //add smoother transition here for assets
              switch (false) {
                case randomInt <= 0.33:
                case randomInt > 0.33 && randomInt <= 0.66:
                default:
              }
              return combinedArray[randomInt];
            } else {
              const regularInt = Math.floor(
                combinedArray.length * nonRandomImgSeed,
              );
              return combinedArray[regularInt];
            }
          }

          const src = GetParticleAssetStaticUrlByName(
            ParticleSelector() as string,
          );

          if (src !== undefined)
            return (
              <div
                key={i}
                style={{
                  zIndex: `${cardObject.transform.zIndex}`,
                  position: "absolute",
                  transform: `translate(${x + initialX}px,${y + initialY}px)`,
                }}
              >
                <Img
                  key={i}
                  src={src}
                  style={{
                    position: "relative",
                    transform: `translate(-50%, -50%) rotate(${0}deg)`,
                    opacity: `${opacity}`,
                  }}
                  width={scale}
                  height={scale}
                />
              </div>
            );
        },
      )}
    </div>
  );
};
