import { type ReactElement } from "react";
import {
  AssetParticleMap,
  GetParticleAssetStaticUrlByName,
} from "@/remotion/assets/particleMap";
import { Img, random, useCurrentFrame } from "remotion";

import { type ReadonlyCustomCardObject } from "../CustomCardObject";
import { calculateXposWithDrag, calculateYposWithDrag } from "./particleUtils";

//trail ends of square to make wavy rectangle w respect to time
//rotate off OR based on velocity
export const DEFAULTS = {
  imgCount: 50,
  accelX: 0,
  accelY: 150,
  xBurstStrength: 1500,
  yBurstStrength: 0,
  seed: 10,
  particleMass: 0.15,
  particleSize: 200,
  rotationSpeed: 0,
  lifespan: 30,
  airResistance: 0.1,
  stepSize: 0.5,
  opacity: 75,
  angleOfElevation: 45,
  isFading: true,
  doRandomizeImages: true,
  isFixedParticleSize: true,
};

export const Trail: React.FC<{
  cardObject: ReadonlyCustomCardObject;
}> = ({ cardObject }): ReactElement => {
  const frame = useCurrentFrame();

  const pi = 3.1415;
  const initialX = cardObject.transform.x;
  const initialY = cardObject.transform.y;

  const particleMass =
    cardObject.metadata.particleMass ?? DEFAULTS.particleMass;
  const airResistance =
    cardObject.metadata.airResistance ?? DEFAULTS.airResistance;
  const gravity = cardObject.metadata.accelY ?? DEFAULTS.accelY; //gravity
  const imgCount = cardObject.metadata.imgCount ?? DEFAULTS.imgCount;
  const spinStrength =
    cardObject.metadata.rotationSpeed ?? DEFAULTS.rotationSpeed;
  const angleOfElevation =
    cardObject.metadata.angleOfElevation ?? DEFAULTS.angleOfElevation;
  const particleLifespan = cardObject.metadata.lifespan ?? DEFAULTS.lifespan;
  const BurstStrength =
    cardObject.metadata.xBurstStrength ?? DEFAULTS.xBurstStrength;
  const size = cardObject.metadata.particleSize ?? DEFAULTS.particleSize;
  const seed = cardObject.metadata.seed ?? DEFAULTS.seed;
  const fixedSize =
    cardObject.metadata.isFixedParticleSize ?? DEFAULTS.isFixedParticleSize;

  const stepSize = cardObject.metadata.stepSize ?? DEFAULTS.stepSize;
  const opacity = cardObject.metadata.opacity ?? DEFAULTS.opacity;
  const isFading = cardObject.metadata.isFading ?? DEFAULTS.isFading;
  const doRandomizeImages =
    cardObject.metadata.doRandomizeImages ?? DEFAULTS.doRandomizeImages;

  const animationRate = 1 / 30;

  const duration = cardObject.sequence.durationInFrames;

  //Allow artist to choose/randomize seed??

  return (
    <div>
      {[...Array(imgCount)].map((_x, i, arr) => {
        // const randomTimingSeed = random(seed * i * (i + i));
        // const spreadSeed = random((i * seed + i) * (i * i) - i) - 0.5;
        // const airResistanceSeed = random(seed * i * i * i * i * i * i);
        // const thetaSeed = random(seed * i);
        // const initialVelocitySeed = random((seed + i) * (i * i * i - i) * i);
        // const isFadingSeed = random(seed * i * i * i);
        // const occilateSeed = random(seed * i * i * i * i * i);
        // const scaleSeed = random(seed * i * i * i * i);
        // const randomImgSeed = random(seed + frame + i);
        // const nonRandomImgSeed = random(seed * (i * i) + i);
        // const particleMassSeed = random(seed * (i + (i * i + i)));
        // const rotateSeed = random(seed * i * i);

        //iterate through arr backwards for correct rendering order
        i = arr.length - 1 - i;

        //time mutated by stepsize var to allow for smaller deltaTime between each particle
        const time = frame * animationRate - i * stepSize * animationRate;
        const maxParticleLifespan = particleLifespan * animationRate;
        if (time < 0) {
          //so trail doesnt appear out of thin air we allow for negative time values
          //return null;
        }
        const randomTimingSeed = random(seed * 2);
        const spreadSeed = random(seed * 3);
        const airResistanceSeed = random(seed * 5);
        const thetaSeed = random(seed + seed);
        const initialVelocitySeed = random(seed - seed);
        const isFadingSeed = random(seed * seed);
        const occilateSeed = random(seed * seed * seed);
        const scaleSeed = random(seed * seed - seed);
        const randomImgSeed = random(seed + frame * (i + 1));
        const nonRandomImgSeed = random(seed * 7);
        const particleMassSeed = random((seed + seed) * 3);
        const rotateSeed = random(seed - seed * seed);

        if (isFading && time >= maxParticleLifespan) {
          return null;
        }

        const theta = -1 * angleOfElevation;
        const thetaInRadians = (theta * pi) / 180;
        const initialVelocity =
          initialVelocitySeed * (BurstStrength - BurstStrength * 0.1) +
          BurstStrength * 0.1;

        const v0X = initialVelocity * Math.cos(thetaInRadians);
        const v0Y = initialVelocity * Math.sin(thetaInRadians);

        // const x = calculateXposWithoutDrag(v0X, time);
        // const y = calculateYposWithoutDrag(v0Y, -gravity, time);

        //opacity lowers linearly as you get further along the trail
        const opacityVal = (((imgCount - i) / imgCount) * opacity) / 100;

        const x = calculateXposWithDrag(v0X, airResistance, time);
        const y = calculateYposWithDrag(v0Y, -gravity, airResistance, time);

        const rotate =
          rotateSeed < 0.5
            ? -1 * rotateSeed * time * spinStrength
            : rotateSeed * time * spinStrength;

        let scale = fixedSize ? size : Math.exp(scaleSeed) * size;
        scale = ((imgCount - i * 0.75) / imgCount) * scale;

        function ParticleSelector() {
          const particleArray = cardObject.metadata.particleArray
            ? cardObject.metadata.particleArray
            : [AssetParticleMap["fireworks_particle_dot_b"].src];
          const userParticle = null;

          const combinedArray = Array.from(particleArray);
          if (userParticle != null) {
            //console.log(combinedArray);
            combinedArray.push(userParticle);
            //console.log(combinedArray);
          }
          //console.log(particleArray);
          //console.log(combinedArray);

          if (doRandomizeImages) {
            const randomInt = Math.floor(combinedArray.length * randomImgSeed);
            return combinedArray[randomInt];
          }
          const regularInt = Math.floor(
            combinedArray.length * nonRandomImgSeed,
          );
          return combinedArray[regularInt];
        }
        const src = GetParticleAssetStaticUrlByName(
          ParticleSelector() as string,
        );
        if (src !== undefined)
          return (
            <div
              key={i}
              style={{
                zIndex: `${cardObject.transform.zIndex}`,
                position: "absolute",
                transform: `translate(${x + initialX}px,${y + initialY}px)`,
              }}
            >
              <Img
                key={i}
                src={src}
                style={{
                  position: "relative",
                  transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
                  opacity: `${opacityVal}`,
                }}
                width={scale}
                height={scale}
              />
            </div>
          );
      })}
    </div>
  );
};
