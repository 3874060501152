"use client";

import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from "react";
import { useConstant } from "@/utils/useConstant";
import { getUrl } from "aws-amplify/storage";
import { continueRender, delayRender, prefetch } from "remotion";
import { proxy, useSnapshot } from "valtio";
import { proxyMap, proxySet, useProxy } from "valtio/utils";

import { type ReadOnlyCardObjects } from "./derivedSchema";

type AssetManagerContext = {
  data: Map<string, string>;
  fetchAssetSignedUrl: (key: string) => Promise<void>;
  loaded: boolean;
};
const AssetManagerContext = createContext<AssetManagerContext | undefined>(
  undefined,
);

type Props = {
  children: ReactNode;
  cardObjects: ReadOnlyCardObjects;
  prefetch?: boolean;
};

const WithInternalContext = (props: Props) => {
  const initialized = useRef(false);
  const currentlyLoadingAssets = useConstant(() => proxySet<string>());
  const data = useConstant(() => proxyMap<string, string>());
  const handle = useConstant(() => delayRender());
  const fetchAssetSignedUrl = useConstant(
    () =>
      function (key: string) {
        if (currentlyLoadingAssets.has(key)) return Promise.resolve();
        currentlyLoadingAssets.add(key);
        return getUrl({ key }).then((result) => {
          data.set(key, result.url.toString());
        });
      },
  );
  const fetchAssetSignedUrlAndPreload = useConstant(
    () =>
      async function (key: string) {
        if (currentlyLoadingAssets.has(key)) return Promise.resolve();
        currentlyLoadingAssets.add(key);
        const { url } = await getUrl({ key });
        data.set(key, url.toString());
        const { waitUntilDone } = prefetch(url.toString());
        await waitUntilDone();
      },
  );

  const state = useConstant(() =>
    proxy({
      data,
      fetchAssetSignedUrl,
      loaded: false,
    }),
  );

  useEffect(() => {
    async function init() {
      const loadFn =
        props.prefetch === true
          ? fetchAssetSignedUrlAndPreload
          : fetchAssetSignedUrl;

      await Promise.allSettled(
        props.cardObjects.flatMap((co) => {
          if (co.type === "IMAGE" || co.type === "VIDEO")
            return loadFn(co.metadata.assetURL);
          else if (co.type === "AUDIO" && co.metadata.audioSrcUrl)
            return loadFn(co.metadata.audioSrcUrl);
          else return [];
        }),
      );
      state.loaded = true;
    }

    if (!initialized.current) {
      initialized.current = true;
      init().catch(console.error);
    }
  }, []);

  return (
    <AssetManagerContext.Provider value={state}>
      {props.children}
    </AssetManagerContext.Provider>
  );
};

export const AssetManagerProvider = (props: Props) => {
  const externalContext = useContext(AssetManagerContext);
  if (externalContext !== undefined) return props.children;
  return <WithInternalContext {...props} />;
};

export const useAssetManager = () => {
  const ctx = useContext(AssetManagerContext);
  if (ctx === undefined)
    throw new Error(
      "useAssetManager must be called from child of AssetManagerProvider",
    );
  return ctx;
};

export const useRemotionAsset = (assetURL: string) => {
  const assetManager = useAssetManager();
  const snap = useSnapshot(assetManager);
  if (assetURL === "") return undefined;
  return snap.data.get(assetURL);
};
