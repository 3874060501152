import { type ReactElement } from "react";
import rose from "public/images/perfect-rose.png";
import { Img, random, useCurrentFrame } from "remotion";

export const FlowerExplosion: React.FC<{ petalCount?: number }> = ({
  petalCount,
}): ReactElement => {
  const frame = useCurrentFrame();
  return (
    <div>
      {[...Array(petalCount || 100)].map((_x, i) => {
        const initialX = 540 + (random(i) - 0.5) * 200;
        const v0X = 50 * (random(i) - 0.5);
        const accelX = 0;
        const initialY = 960 + (random(-i) - 0.5) * 200;
        const v0Y = 100 * (random(-i) - 0.7);
        const accelY = 3;
        const x = 0.5 * accelX * frame * frame + v0X * frame + initialX;
        const y = 0.5 * accelY * frame * frame + v0Y * frame + initialY;
        const rotate = frame * (0.5 - random(i)) * 20;
        const scale = random(i) > 0.5 ? "4%" : "5%";
        return (
          <Img
            key={i}
            src={rose.src}
            style={{
              position: "absolute",
              transform: `translate(${x}px, ${y}px) rotate(${rotate}deg)`,
            }}
            width={scale}
            height={scale}
          />
        );
      })}
    </div>
  );
};
