import { type DeepReadonly } from "@/utils/tsmagic";
import { z } from "zod";

import { BaseCardObjectSchema, CardTypes } from "./baseSchema";

const audioMetadataSchema = z.object({
  volume: z.number(),
  startFrom: z.number(),
  playbackRate: z.number(),
  audioSrcUrl: z.string().optional(),
});

export const AudioCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: audioMetadataSchema,
  type: z.literal(CardTypes.Values.AUDIO),
});

const customMetadataSchema = z.object({
  customObjectName: z.string(),
  particleArray: z.array(z.string()).optional(),
  userUploadedParticle: z.string().optional(),

  imgCount: z.number().positive().optional(),

  accelY: z.number().optional(),
  accelX: z.number().optional(),
  xBurstStrength: z.number().optional(),
  yBurstStrength: z.number().optional(),

  seed: z.number().optional(),

  particleMass: z.number().positive().optional(),
  particleSize: z.number().positive().optional(),
  lifespan: z.number().positive().optional(),
  rotationSpeed: z.number().optional(),
  spread: z.number().positive().optional(),

  airResistance: z.number().positive().optional(),
  stepSize: z.number().positive().optional(),
  opacity: z.number().positive().optional(),

  angleOfElevation: z.number().optional(),
  angleSplice: z.number().positive().optional(),

  scaleRangePercent: z.number().gte(0).lte(100).optional(),
  contrastRangePercent: z.number().gte(0).lte(100).optional(),
  invertRangePercent: z.number().gte(0).lte(100).optional(),
  brightnessRangePercent: z.number().gte(0).lte(100).optional(),

  scaleFrequency: z.number().optional(),
  contrastFrequency: z.number().optional(),
  invertFrequency: z.number().optional(),
  brightnessFrequency: z.number().optional(),

  isFading: z.boolean().optional(),
  isVariableSize: z.boolean().optional(),
  isVariableShape: z.boolean().optional(),
  doRandomizeImages: z.boolean().optional(),
  isFromSinglePoint: z.boolean().optional(),
  isFixedParticleSize: z.boolean().optional(),
});

export const CustomCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: customMetadataSchema,
  type: z.literal(CardTypes.Values.CUSTOM),
});

const imageMetadataSchema = z.preprocess(
  // older cards have card object metadata as json
  (arg) => {
    if (typeof arg === "object") return arg;
    if (typeof arg === "string") return JSON.parse(arg);
  },
  z.object({
    imageStyle: z.any(),
    assetURL: z.string(),
  }),
);

export const ImageCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: imageMetadataSchema,
  type: z.literal(CardTypes.Values.IMAGE),
});

const RawTextStyleVars = z.object({
  color: z.string(),
  bgColor: z.string(),
  bold: z.boolean(),
  italic: z.boolean(),
  font: z.string(),
  underline: z.boolean(),
  overline: z.boolean(),
});

const rawTextMetadataSchema = z.object({
  value: z.string(),
  style: RawTextStyleVars,
});

export const RawTextCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: rawTextMetadataSchema,
  type: z.literal(CardTypes.Values.RAWTEXT),
});
const richTextMetadataSchema = z.object({
  lexicalJSON: z.any(),
  rawHTML: z.string(),
});

export const RichTextCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: richTextMetadataSchema,
  type: z.literal(CardTypes.Values.RICHTEXT),
});

const videoMetadataSchema = z.object({
  src: z.string().optional(),
  assetURL: z.string(),
  volume: z.number().default(0), // for older objects without this property, defaults to 0 so they dont get unmuted
});

export const VideoCardObjectSchema = BaseCardObjectSchema.extend({
  metadata: videoMetadataSchema,
  type: z.literal(CardTypes.Values.VIDEO),
  version: z.number().optional().default(0),
});

export const CardObjectSchema = z.discriminatedUnion("type", [
  AudioCardObjectSchema,
  CustomCardObjectSchema,
  ImageCardObjectSchema,
  RawTextCardObjectSchema,
  RichTextCardObjectSchema,
  VideoCardObjectSchema,
]);
export const RootCompSchema = z.object({
  cardObjects: z.array(CardObjectSchema),
});

export type CardObjectType = z.infer<typeof CardObjectSchema>;

export type ReadOnlyCardObject = DeepReadonly<CardObjectType>;
export type ReadOnlyCardObjects = Readonly<ReadOnlyCardObject[]>;
