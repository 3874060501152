import React from "react";
import Link from "next/link";
import { CustomCardObjectComponent } from "@/remotion/cardObjects/CustomCardObject";
import { RichTextCardObjectComponent } from "@/remotion/cardObjects/RichTextCardObject";
import { CardObjectFactory } from "@/remotion/editor/CardObjectFactory";
import { type DeepReadonly } from "@/utils/tsmagic";
import { AbsoluteFill, Sequence } from "remotion";

import { type WrapVideo } from "@matan/database";

type Props = {
  wrapVideo: DeepReadonly<WrapVideo>;
};

const Endplate = ({ wrapVideo }: Props) => {
  const factory = new CardObjectFactory(
    wrapVideo.durationInFrames,
    wrapVideo.resolutionX,
    wrapVideo.resolutionY,
  );

  // watermark
  const watermark = factory.createCardObject("CUSTOM", "Watermark");
  watermark.sequence = {
    from: 0,
    durationInFrames: 60,
  };
  watermark.animations = [
    {
      name: "zoomIn",
      startFrame: 0,
      durationInFrames: 30,
    },
  ];
  watermark.isEditable = false;
  watermark.transform = {
    x: 540,
    y: 960,
    scale: 200,
    zIndex: 10,
    rotate: 0,
  };

  // copyright wrap.gifts text
  const watermarkText = factory.createCardObject("RICHTEXT");
  watermarkText.sequence = {
    from: 0,
    durationInFrames: 60,
  };
  watermarkText.label = "WaterMarkText";
  watermarkText.isEditable = false;
  watermarkText.transform = {
    x: 540,
    y: 1850,
    scale: 200,
    zIndex: 15,
    rotate: 0,
  };
  watermarkText.metadata.rawHTML = `<p dir="ltr"><span style="font-size: 25x; color: rgb(255,255,255); ">©Wrap.Gifts 2024</span></p>`;

  // send a reply text
  const sendAReplyText = factory.createCardObject("RICHTEXT");
  sendAReplyText.sequence = {
    from: 10,
    durationInFrames: 50,
  };
  sendAReplyText.metadata.rawHTML = `<p dir="ltr"><span style="font-size: 25x; color: rgb(255,255,255); text-decoration: underline;">Send a Free Reply</span></p>`;
  sendAReplyText.animations.push({
    name: "zoomIn",
    startFrame: 10,
    durationInFrames: 30,
  });
  sendAReplyText.transform = {
    x: 540,
    y: 460,
    scale: 450,
    zIndex: 10,
    rotate: 0,
  };
  sendAReplyText.isEditable = false;

  // at text
  const atText = factory.createCardObject("RICHTEXT");
  atText.sequence = {
    from: 20,
    durationInFrames: 60,
  };
  atText.isEditable = false;
  atText.animations.push({
    name: "zoomIn",
    startFrame: 20,
    durationInFrames: 30,
  });
  atText.transform = {
    x: 540,
    y: 560,
    scale: 450,
    zIndex: 15,
    rotate: 0,
  };
  atText.metadata.rawHTML = `<p dir="ltr"><span style="font-size: 25x; color: rgb(255,255,255); ">at</span></p>`;

  return (
    <AbsoluteFill style={{ backgroundColor: "rgb(192 132 252)" }}>
      {/* invisibile link hack in front of text. the actual text hitbox is too small  */}
      <Sequence from={0} layout="none">
        <Link
          href="/?category=free"
          target={"_blank"}
          style={{
            position: "absolute",
            display: "block",
            height: "400px",
            width: "650px",
            transformOrigin: "center",
            zIndex: 50,
            top: 350,
            left: 215,
          }}
        />
      </Sequence>
      <RichTextCardObjectComponent cardObject={atText} />
      <RichTextCardObjectComponent cardObject={sendAReplyText} />
      <RichTextCardObjectComponent cardObject={watermarkText} />
      <CustomCardObjectComponent cardObject={watermark} />
      <RichTextCardObjectComponent cardObject={sendAReplyText} />
    </AbsoluteFill>
  );
};

export default Endplate;
