// DATA
import { type DeepReadonly } from "@/utils/tsmagic";
import { AbsoluteFill, Sequence } from "remotion";

import { type WrapVideo } from "@matan/database";

import { AudioCardObjectComponent } from "./cardObjects/AudioCardObject";
import { CustomCardObjectComponent } from "./cardObjects/CustomCardObject";
import { ImageCardObjectComponent } from "./cardObjects/ImageCardObject";
import { RawTextCardObjectComponent } from "./cardObjects/RawTextCardObject";
import { RichTextCardObjectComponent } from "./cardObjects/RichTextCardObject";
import { VideoCardObjectComponent } from "./cardObjects/VideoCardObject";
import { AssetManagerProvider } from "./core/AssetManager";
import { type ReadOnlyCardObjects } from "./core/derivedSchema";
import Endplate from "./Endplate";

export type RootCompositionProps = {
  cardId: string | undefined;
  withEndplate?: boolean;
  baseFont?: string;
  data: {
    cardObjects: ReadOnlyCardObjects;
    wrapVideo: DeepReadonly<WrapVideo>;
  } | null;
  background?: string;
};

export const RootComposition = ({
  data,
  withEndplate,
  baseFont,
  background = "white",
}: RootCompositionProps) => {
  if (data === null) {
    throw new Error("Data was not fetched");
  }
  return (
    <AssetManagerProvider cardObjects={data.cardObjects}>
      <AbsoluteFill
        style={{
          background: background,
          fontFamily: baseFont,
          zIndex: 0,
        }}
      >
        <Sequence
          durationInFrames={data.wrapVideo.durationInFrames}
          layout="none"
        >
          <RootCompositionInner cardObjects={data.cardObjects} />
        </Sequence>
      </AbsoluteFill>

      {withEndplate ? (
        <Sequence
          from={data.wrapVideo.durationInFrames + 1}
          durationInFrames={60}
          layout="none"
        >
          <Endplate wrapVideo={data.wrapVideo} />
        </Sequence>
      ) : null}
    </AssetManagerProvider>
  );
};

export const RootCompositionInner: React.FC<{
  cardObjects: ReadOnlyCardObjects;
}> = ({ cardObjects }) => {
  return cardObjects.map((item) => {
    switch (item.type) {
      case "RAWTEXT":
        return <RawTextCardObjectComponent key={item.id} cardObject={item} />;
      case "RICHTEXT":
        return <RichTextCardObjectComponent key={item.id} cardObject={item} />;
      case "IMAGE":
        return <ImageCardObjectComponent key={item.id} cardObject={item} />;
      case "VIDEO":
        return <VideoCardObjectComponent key={item.id} cardObject={item} />;
      case "AUDIO":
        return <AudioCardObjectComponent key={item.id} cardObject={item} />;
      case "CUSTOM":
        return <CustomCardObjectComponent key={item.id} cardObject={item} />;
      default:
        return null;
    }
  });
};

export default RootComposition;
